import React from 'react'
import { Row, Col} from 'react-bootstrap';

function FollowIcons() {
  return (

        <div className='py-3 codelinks'>   
                 
                <Row>
                  <Col ><a href="https://facebook.com/robinokwanma" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a></Col>
                  <Col>                    <a
                      href="https://x.com/robinokwanma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                        viewBox="0 0 19 22"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a></Col>
                  <Col><a href="https://linkedin.com/in/robinokwanma" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a></Col>
                  <Col><a href="https://wa.me/2348148190856" target="_blank" rel="noreferrer"><i className="bi bi-whatsapp"></i></a></Col>
                </Row>

        </div>

  )
}

export default FollowIcons