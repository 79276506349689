import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Row, Col, Card } from "react-bootstrap";
import "../styling/Contact.css";

export const ContactForm = () => {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_beg4ok8",
        "template_hm8m12l",
        form.current,
        "ZhPCouDfw0H83KOf5"
      )
      .then(
        (result) => {
          console.log(result.text);
          setEmailSent(true); // update the emailSent state to true
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="text-center py-3 my-3">
      <h1>
        Contact Robin <i className="bi bi-envelope-check-fill"></i>
      </h1>
      <Row className=" my-2 p-3 contactcard">
        <Col lg={6} mx-auto="true">
          <Card className="my-2 p-4 rounded cardmain">
            <Card.Title>
              <p>
                <b>Email Me</b>
              </p>
            </Card.Title>

            <Card.Body className="contactcardbody">
              <form ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder=" full name"
                  required
                />
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Johndoe@gmail.com"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                />
                <label>Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="type your message here"
                  required
                />
                <button
                  className="btn btn-dark submit-btn"
                  type="submit"
                  value="Send"
                >
                  Send <i className="bi bi-send-fill"></i>
                </button>
              </form>
              {emailSent && (
                <h6 className="text-success">
                  Email successfully sent! <br /> Hi there, I will get back to
                  you as soon as i can. Cheers!
                </h6>
              )}
              <div className="py-3 followbuttons">
                <Row>
                  <Col>
                    <a
                      href="https://facebook.com/robinokwanma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://x.com/robinokwanma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.2"
                        height="19.2"
                        fill="currentColor"
                        class="bi bi-twitter-x"
                        viewBox="0 0 15 18"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </a>
                  </Col>
                  {/* <Col>
    <a href="https://x.com/robinokwanma" target="_blank" rel="noreferrer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="bi bi-twitter-x" style="width: 1em; height: 1em;">
        <path d="M8.6.75h2.454l-5.36 6.142L12 15.25H7.063l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L8.601.75Zm-.86 13.028h1.36L.323 2.145H-.135z"/>
      </svg>
    </a>
  </Col> */}
                  <Col>
                    <a
                      href="https://linkedin.com/in/robinokwanma"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://wa.me/2348148190856"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-whatsapp"></i>
                    </a>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ContactForm;
