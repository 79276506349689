import React from 'react'
import { Row, Col, Container, Carousel, Badge } from 'react-bootstrap';

function PastProjects() {
  return (
    <div>
      <h1>Projects</h1>
      <p className="projectsection">
        <strong>Here are some projects I've worked on,</strong>
      </p>
      <Container fluid>
        <hr />
        <Carousel>
          {[
            {
              img: "/images/nusize.png",
              title: "Nu-Size Sewing Pattern(Mobile App)",
              description: "Convert Half-Scale Sewing Patterns to Full-Scale Fast & Easy!",
              badges: ["React Native", "Expo", "Javascript", "Django", "Python"],
              links: [
                { url: "https://play.google.com/store/apps/details?id=com.bcimageapp", text: "Google Playstore (Android)" },
                { url: "https://apps.apple.com/us/app/nu-size-sewing-pattern/id6483004797", text: "Apple App Store (iOS)" }
              ]
            },
            {
              img: "/images/kn1ghts.png",
              title: "Kn1ght(Mobile App)",
              description: "Create Short Videos from text using Kn1ght animation!",
              badges: ["React Native", "Expo", "Javascript"],
              links: [
                { url: "https://play.google.com/store/apps/details?id=com.kn1ghts", text: "Google Playstore (Android)" },
                { url: "https://apps.apple.com/app/id6470290408", text: "Apple App Store (iOS)" }
              ]
            },
            {
              img: "/images/mymedicalbank.png",
              title: "MyMedicalBank (Mobile App)",
              description: "Nigeria's first Personal Health Record App, available for Android and iOS devices.",
              badges: ["React Native", "Expo", "Javascript", "Axios"],
              links: [
                { url: "https://play.google.com/store/apps/details?id=com.mymedicalbankphr", text: "Google Playstore (Android)" },
                { url: "https://apps.apple.com/ng/app/mymedicalbank/id6464156472", text: "Apple App Store (iOS)" }
              ]
            },
            {
              img: "/images/jobscraper.png",
              title: "Job Scraper for Nigerian Jobs",
              description: "A fully functional website that aggregates job listings and allows them to be downloaded in various formats.",
              badges: ["Django", "Python", "Jquery", "Bootstrap"],
              links: [
                { url: "https://scraper.cyberdata.ng", text: "Visit Site" },

              ]
            },
            {
              img: "/images/cyberdatahub.png",
              title: "CYBER DATA HUB (Mobile App)",
              description: "A comprehensive Virtual Top-Up application offering airtime and data through automated APIs, available on Android and iOS.",
              badges: ["React Native", "React", "Javascript", "Axios"],
              links: [
                { url: "https://play.google.com/store/apps/details?id=com.supervas.cyberdatahub", text: "Google Playstore (Android)" },
                { url: "https://apps.apple.com/ng/app/cyber-data-hub/id6467631238", text: "Apple App Store (iOS)" }
              ]
            },
            {
              img: "/images/techhive.png",
              title: "TechHive",
              description: "Co-founded with my friend Michael Olu, Tech Hive fosters a dynamic tech community based in Port Harcourt, Nigeria.",
              badges: ["Django", "Python", "React", "Bootstrap"],
              links: [
                { url: "https://techhive.ng", text: "Visit Site" },

              ]
            },
            {
              img: "/images/myportfolio.png",
              title: "MY PORTFOLIO V2",
              description: "My personal portfolio, a single-page website featuring extensive use of Bootstrap. Contact me to view the code.",
              badges: ["React", "Bootstrap", "Javascript", "HTML/CSS"],
              links: [
                { url: "https://play.google.com/store/apps/details?id=com.supervas.cyberdatahub", text: "Google Playstore (Android)" },
                { url: "https://justrobin.dev", text: "Visit Site" }
              ]
            }
          ].map((project, index) => (
            <Carousel.Item key={index} className="projectsection my-4 py-4">
              <Row className="projectsection mx-3">
                <Col md="6">
                  <img src={project.img} alt="Project View" className="todoimg" />
                </Col>
                <Col md="6">
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                  <div className="d-flex flex-wrap my-2">
                    {project.badges.map((badge, index) => (
                      <Badge key={index} className="mx-2 bg-success my-2">{badge}</Badge>
                    ))}
                  </div>
                  <div className="codelinks">
                    {project.links.map((link, linkIndex) => (
                      <a key={linkIndex} href={link.url} target="_blank" rel="noopener noreferrer" className="d-block">
                        <i className="bi bi-link-45deg">{link.text}</i>
                      </a>
                    ))}
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
        <hr />
      </Container>
    </div>
  );
}

export default PastProjects;